import * as React from "react";
import Seo from "../components/seo"
import { Zoom, Slide, Fade } from 'react-reveal';
import russell from '../images/russell.png';
import gavin from '../images/gavin.png';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import cross from '../images/cross.svg';
import Forward from '../components/forward';
import Employee from '../components/employee';
import { StaticImage } from "gatsby-plugin-image"

var settings = {
  dots: false,
  arrows: false,
  infinite: true,
  speed: 300,
  autoplaySpeed: 4000,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: false,
  swipe: false,
  adaptiveHeight: true,
};

const PeoplePage = () => {
  const [slider, setSlider] = React.useState();
  const [current, setCurrent] = React.useState();

  const goToSlide = (id) => {
    if (slider) {
      setCurrent(id);
      slider.slickGoTo(id);
    }
  };

  React.useEffect(() => {
    setCurrent(0);
  }, []);

  return (
    <div class="relative">
      <Seo title="People" />
      <div class="container mx-auto relative">
        <div class="grid lg:grid-cols-12 lg:mt-28 xl:mt-44 lg:px-0 pt-10 lg:0 lg:p-0">
          <div class="col-span-12 lg:col-span-5 flex flex-col">
            <h2 class="uppercase text-3xl font-sans font-medium tracking-wider lg:pl-10 mb-6 lg:mb-0">
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500}>Peo</Slide>
              </div>
              <div class="overflow-hidden mb-6">
                <Slide bottom duration={500} delay={100}>ple</Slide>
              </div>
            </h2>
          </div>
          <div class="col-span-7 font-sans text-2xl lg:text-3xl relative">
            <div style={{ width: 83 }} class="invisible lg:visible absolute -left-28 -top-12">
              <Fade delay={200} left distance="18px"><img src={cross} /></Fade>
            </div>
            <div>
              <h1 class="hidden lg:block uppercase font-sans text-lg lg:text-2lg xl:text-xl tracking-wider mb-20">
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500}>IF YOU HAVE ALREADY</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={50}>PROVED YOURSELF, IF YOUR</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={100}> TALENT AND WORK ETHIC</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={150}> MATCHES YOUR AMBITION</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={200}>THEN THIS JUST MIGHT</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={250}>BE THE MOMENT YOUR LIFE</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={300}>BEGINS TO TRACE A NEW</Slide>
                </div>
                <div class="overflow-hidden">
                  <Slide bottom duration={500} delay={350}>PATH.</Slide>
                </div>
              </h1>
            </div>
            <div class="lg:hidden">
              <p class="uppercase font-sans text-lg lg:text-xl tracking-wider mb-10">
                <Fade>
                  IF YOU HAVE ALREADY PROVED YOURSELF, IF YOUR TALENT AND WORK ETHIC MATCHES YOUR AMBITION THEN THIS JUST MIGHT BE THE MOMENT YOUR LIFE BEGINS TO TRACE A NEW PATH.
                </Fade>
              </p>
            </div>
            <div class="font-sans text-gray text-base lg:text-lg font-light tracking-wide mb-10">
              <p class="mb-10">
                <Fade delay={100}>
                  We do not accept applications, CVs, pitch decks or speculative business proposals.
                </Fade>
              </p>
              <p class="mb-0">
                <Fade delay={100}>
                  We handpick, exclusively.
                </Fade>
              </p>
            </div>
          </div>
        </div>

        <div>
          <div class="grid grid-cols-12 mt-20">
            <div class="col-span-12 lg:col-span-5 relative">
              <h2 class="relative uppercase text-2xl lg:text-3xl font-sans font-medium tracking-wider lg:pl-10">
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500}>Gavin</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={100}>Johns</Slide>
                </div>
                <div class="overflow-hidden mb-4">
                  <Slide bottom duration={500} delay={200}>tone</Slide>
                </div>
                <div style={{ width: 83 }} class="invisible lg:visible absolute -bottom-8 left-80">
                  <Fade delay={200} right distance="18px"><img src={cross} /></Fade>
                </div>
              </h2>
            </div>
            <div class="col-span-12 lg:col-span-7">
              <Fade duration={1200}>
                <img src={gavin} />
              </Fade>
            </div>
          </div>
          <div class="grid grid-cols-12 mt-10 lg:mt-32">
            <div class="col-span-12 lg:col-span-5 relative">
              <p class="font-sans text-gray text-base lg:text-lg font-light tracking-wide mb-10 lg:pl-10 pr-20">
                <span class="font-medium text-black">
                  <Fade delay={100}>CEO</Fade>
                </span>
                <Fade delay={150}>
                  Gavin is the founder of HG Ventures.
                </Fade>
              </p>
            </div>
            <div class="col-span-12 lg:col-span-7 text-gray">
              <div class="lg:px-0 font-sans text-base lg:text-lg font-light tracking-wide mb-10">
                <p class="mb-10">
                  <Fade delay={100}>
                    Gavin launched his first business,  a healthcare events and publishing company in 2004 which was acquired by EMAP, the business-to-business multiplatform media group owned by APAX partners and The Guardian Media Group in 2009. Gavin then launched the award winning Hunter Healthcare in 2011, facilitating a Private Equity backed MBO in 2021. At the point of exit revenues were in excess of £44m. Gavin launched HG Ventures in 2017. Gavin's other businesses include a growing commercial property portfolio.
                  </Fade>
                </p>
                <p class="mb-10">
                  <Fade delay={150}>
                    Gavin lives in the Cotswolds with his wife and two sons. Interests outside of work include: fitness, travel, flying, family life, country sports and property.
                  </Fade>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="grid grid-cols-12 mt-20">
            <div class="col-span-12 lg:col-span-5 relative">
              <h2 class="relative uppercase text-2xl lg:text-3xl font-sans font-medium tracking-wider lg:pl-10">
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500}>Rus</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={100}>Sell</Slide>
                </div>
                <div class="overflow-hidden mb-6">
                  <Slide bottom duration={500} delay={200}>Cleme</Slide>
                </div>
                <div class="overflow-hidden mb-4">
                  <Slide bottom duration={500} delay={300}>nts</Slide>
                </div>
                <div style={{ width: 83 }} class="invisible lg:visible absolute -bottom-8 left-64">
                  <Fade delay={200} right distance="18px"><img src={cross} /></Fade>
                </div>
              </h2>
            </div>
            <div class="col-span-12 lg:col-span-7">
              <Fade duration={1200}>
                <img src={russell} />
              </Fade>
            </div>
          </div>
          <div class="grid grid-cols-12 mt-10 lg:mt-32">
            <div class="col-span-12 lg:col-span-5 relative">
              <p class="text-gray font-sans text-base lg:text-lg font-light tracking-wide mb-10 lg:pl-10 pr-20">
                <span class="text-black font-medium">
                  <Fade delay={100}>Non-Executive Chairman</Fade>
                </span>
                <Fade delay={150}>
                  Russell is the former CEO of SThree Plc an international specialist staffing business with approximately £1bn in annual revenue.
                </Fade>
              </p>
            </div>
            <div class="text-gray col-span-12 lg:col-span-7">
              <div class="lg:px-0 font-sans text-base lg:text-lg font-light tracking-wide mb-10">
                <p class="mb-10">
                  <Fade delay={100}>
                    Russell joined SThree plc in 1986 shortly after its inception becoming its second employee. He was appointed CEO in 2004 and led the flotation of the business on the London Stock Exchange in November 2005. During Russell’s tenure as CEO, the business grew from twenty offices of which five were overseas, to 64 offices in twenty countries. Russell is an Angel Investor and has stakes in a number of start-up businesses particularly in the recruitment industry and in the technology space.
                  </Fade>
                </p>
                <p class="mb-10">
                  <Fade delay={150}>
                    Russell has a Degree in Government from the University of Essex & is a Graduate of the Advanced Management Program at the Wharton School of Business, University of Pennsylvania. Russell is also a former Chairman of the Association of Professional Staffing Companies (APSCo).
                  </Fade>
                </p>
                <p class="mb-10">
                  <Fade delay={150}>
                    Russell lives in London, Norfolk and Ibiza with his wife, and has 3 children at university across the globe.
                  </Fade>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="lg:mx-10">
          <div class="grid grid-cols-12 gap-y-10 lg:gap-x-10 lg:gap-y-20 lg:gap-32 lg:mt-20 mb-20">
            <div class="col-span-12 lg:col-span-6">
              <Employee
                name="MIKE LINDRIDGE"
                description="Group Financial Controller"
                img={<StaticImage src="../images/people/mike.jpeg" />}
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Employee
                name="TANIA BOWERS"
                description="Group Legal Advisor"
                img={<StaticImage src="../images/people/tania.jpeg" />}
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Employee
                name="FAYE EVANS"
                description="Head of Office"
                img={<StaticImage src="../images/people/faye.jpeg" />}
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Employee
                name="JESSIE MCGREGOR"
                description="Head of Group Marketing"
                img={<StaticImage src="../images/people/jessie.jpeg" />}
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Employee
                name="Leena Patel"
                description="Operations and Mobilisation Officer"
                img={<StaticImage src="../images/people/leena.jpeg" />}
              />
            </div>
            <div class="col-span-12 lg:col-span-6">
              <Employee
                name="Kiran Patel"
                description="Head of Learning and Development"
                img={<StaticImage src="../images/people/kiran.jpeg" />}
              />
            </div>
          </div>
        </div>

        <Forward to="/build/" />
      </div>
    </div>
  )
};

export default PeoplePage
