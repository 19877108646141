import * as React from "react";
import { Slide, Fade } from 'react-reveal';


const Employee = ({ name, description, img }) => {
    return (
        <div class="flex gap-4 lg:gap-10 xl:gap-20">
            <div class="flex-none w-20 lg:w-52">
                <Fade delay={400} duration={1200}>
                    {img}
                </Fade>
            </div>
            <div class="flex-1">
                <div class="font-sans text-gray text-md lg:text-lg font-light tracking-wide">
                    <p>
                        <span class="text-black font-medium uppercase">
                            <Slide bottom duration={300}>{name}</Slide>
                        </span>
                        <Slide bottom duration={300}>
                            {description}
                        </Slide>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Employee;